<style type="text/css">
@import "~@/assets/css/common.css";
</style>
<style type="text/css" scoped>
.log-tips {
  margin-top: 80px;
}

.login-main {
  padding: 0 91px;
}

.tabs {
  width: 330px;
}

.log-r .input-group {
  width: 100%;
}

.form-control {
  padding: unset;
}

/deep/ .el-input__inner {
  border: none;
  height: 100%;
  line-height: 0;
}

/deep/ .el-select > .el-input {
  height: 100%;
}
</style>
<script>
// import {
//   required,
//   email
// } from "vuelidate/lib/validators";
import $ from "jquery";
import LoginLeft from "@/views/pages/account/login-left";
import LoginTop from "@/views/pages/account/login-top";
import {
  userLogin,
  phoneLogin,
  listenEnterEv,
  sendPhoneCode,
  initSystem,
  loginFast,
} from "@/api/index.js";
import { setStore } from "@/libs/storage.js";
import Verify from "@/components/verifition/Verify";

// import {
//   authMethods,
//   authFackMethods,
//   notificationMethods
// } from "@/state/helpers";

export default {
  components: {
    LoginLeft,
    LoginTop,
    Verify,
  },
  data() {
    return {
      isPasswordLogin: true,
      committing: false,
      committingPhoneCode: false,
      isLogout: "",
      headerT: "",
      headerS: "",
      rules: {
        username: [
          {
            required: true,
            message: "请输入身份证件号",
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "change",
          },
        ],
      },
      rulesTel: {
        mobilePhone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "change",
          },
          {
            min: 11,
            max: 11,
            message: "长度为11位数字",
            trigger: "change",
          },
        ],
        phoneCode: [
          {
            required: true,
            message: "请输入手机验证码",
            trigger: "change",
          },
        ],
      },

      //form 提交
      formDataUsername: {
        username: "",
        password: "",
        uuid: "",
        captchaVO: "",
      },
      formDataTel: {
        mobilePhone: "",
        phoneCode: "",
        captchaVO: "",
        uuid: "",
      },
      //手机号码验证
      isPhone: false,
      baseConfig: {
        WebsiteName: "",
        SystemYear: "",
      },
      zjlxList: [],
    };
  },
  created() {
    // 已登录则直接跳转
    // this.getCookie();
    // initSystem().then((res) => {
    //   if (res.status) {
    //     this.baseConfig = res.data;
    //     localStorage.setItem("BaseConfig", JSON.stringify(res.data));
    //   }
    // });
    // let path = window.location.pathname;
    // let pathArr = path.split("/");
    // this.isLogout = pathArr.indexOf("login");
    // /*   var userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    //      var time = JSON.parse(window.localStorage.getItem("requestTime"));
    //      console.log(userInfo)
    //      // if (userInfo && time < 7200000) {
    //      //   this.$router.push("/login");
    //      // }*/
  },

  methods: {
    success(params) {
      this.committing = true;
      // params 返回的二次验证参数, 和登录参数一起回传给登录接口，方便后台进行二次验证
      // this.$router.push('/home');
      this.formDataUsername.captchaVO = JSON.stringify(params);
      this.formDataTel.captchaVO = JSON.stringify(params);
      // this.checkLogin();
      var form = this.isPasswordLogin ? "formDataUsername" : "formDataTel";
      if (this.isPasswordLogin) {
        userLogin(this[form]).then((res) => {
          this.handlerMsg(res);
        });
      } else {
        phoneLogin(this[form]).then((res) => {
          this.handlerMsg(res);
        });
      }
      this.committing = false;
    },
    useVerify() {
      this.$refs.verify.refresh();
      this.$refs.verify.show();
    },
    handlerMsg(msg) {
      if (msg.status) {
        // 正确
        setStore("accessToken", msg.data.token);
        setStore("userInfo", msg.data);
        if (msg.data.mmqd == 0) {
          this.$router.push("./weekPassword");
          return;
        }
        if (this.$route.query.from) {
          {
            this.$router.push("/" + this.$route.query.from);
          }
        } else {
          this.$router.push("/home");
        }
      } else {
        // 错误
        // _this.imgCodeChange();
      }
      this.committing = false;
    },
    // 登录按钮点击后触发的事件 通过 isPasswordLogin 判断使用什么方式进行登录
    checkLogin: function () {
      // 无论是电话号码验证还是账户密码验证都走这个处理函数
      this.setCookie(this.formDataUsername.username, 7);

      this.formDataUsername.username = this.formDataUsername.username.toUpperCase();
      var form = this.isPasswordLogin ? "formDataUsername" : "formDataTel";
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.useVerify();
        }
      });
    },
    /* 发送手机验证码*/
    sendPhoneCode: function () {
      var formDate = {
        mobilePhone: this.formDataTel.mobilePhone,
        code: this.formDataTel.code,
        uuid: this.formDataTel.uuid,
      };
      return sendPhoneCode(formDate);
    },
    /*获取验证码*/
    getCode: function () {
      this.checkPhone(); //验证手机号码
      var _this = this;
      if (this.isPhone && !this.committingPhoneCode) {
        /*如果状态正确*/
        var formDate = {
          mobilePhone: this.formDataTel.mobilePhone,
        };
        this.committingPhoneCode = true;
        this.sendPhoneCode(formDate).then((res) => {
          if (res.status) {
            _this.resetCode(60); //倒计时
            _this.$message({
              message: res.message,
              type: "success",
            });
          } else {
            // this.imgCodeChange();
            _this.resetCode(5);
          }
        });
      } else {
        $("#phone").focus();
      }
    },
    //验证手机号码
    checkPhone: function () {
      var pattern = /^1[0-9]{10}$/;
      this.isPhone = true;
      if (this.formDataTel.mobilePhone === "") {
        this.$message.error("请输入手机号码");
        this.isPhone = false;
        return this.isPhone;
      }
      if (!pattern.test(this.formDataTel.mobilePhone)) {
        this.$message.error("请输入正确的手机号码");
        this.isPhone = false;
        return this.isPhone;
      }
    },
    //倒计时
    resetCode: function (second) {
      var timer = null;
      var _this = this;
      timer = setInterval(function () {
        second -= 1;
        if (second > 0) {
          $(".verification-code").css("cursor", "no-drop");
          $(".verification-code").text(second + "秒");
        } else {
          clearInterval(timer);
          $(".verification-code").css("cursor", "pointer");
          $(".verification-code").text("发送验证码");
          _this.committingPhoneCode = false;
        }
      }, 1000);
    },
    // 设置cookie
    setCookie(name, exdays) {
      var exdate = new Date(); // 获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); // 保存的天数
      // 字符串拼接cookie
      window.document.cookie =
          "username" + "=" + name + ";path=/;expires=" + exdate.toGMTString();
    },
    // 读取cookie 将用户名和密码回显到input框中
    getCookie() {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split("; "); // 这里显示的格式需要切割一下自己可输出看下
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split("="); // 再次切割
          // 判断查找相对应的值
          if (arr2[0] === "username") {
            this.formDataUsername.username = arr2[1]; // 保存到保存数据的地方
          }
        }
      }
    },
    // 切换登录
    changeLogin(data) {
      var _this = this;
      if (data == "account") {
        _this.isPasswordLogin = true;
        return;
      }
      _this.isPasswordLogin = false;
    },
    // 判断浏览器
    checkBrowserr() {
      // var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
      // var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器
      var isIE = this.isIE(); //判断是否IE<11浏览器
      if (isIE) {
        this.$alert(
            "您的浏览器版本过低，请前往下载合适的浏览器",
            "提示",
            {
              confirmButtonText: "确定",
              callback: () => {
                window.location.href = "/browser";
              },
            }
        );
      }
    },
    isIE() {
      //ie
      if (!!window.ActiveXObject || "ActiveXObject" in window) return true;
      else return false;
    },
    // 管理员不需要知道考生的账号密码，可直接进入考生主页
    directEntry() {
      let fastObj = {
        t: this.headerT,
        s: this.headerS,
      };
      if (this.headerS && this.headerT) {
        loginFast(fastObj).then((res) => {
          if (res.status) {
            setStore("accessToken", res.data.token);
            setStore("userInfo", res.data);
            this.$router.push("/home");
          } else {
            this.$router.push("/pages/error-500");
          }
        });
      }
    },
  },

  mounted() {
    window.location.href = "https://www.schrss.org.cn/ssologin/login?service=https%3A%2F%2Fersuserapi.jdpta.com%2Fyethan%2Flogin%2Fcas";
    //console.log(this.$route);
    // this.checkBrowserr();
    // listenEnterEv(this.checkLogin);
    // this.headerT = this.$route.query.t ? this.$route.query.t : "";
    // this.headerS = this.$route.query.s ? this.$route.query.s : "";
    // // listenEnterEv(this.checkPhone);
    // if (this.headerS && this.headerT) {
    //   this.directEntry();
    // }
  },
};
</script>

<template>
  <div>
    <Verify
        @success="success"
        mode="pop"
        captchaType="blockPuzzle"
        :imgSize="{ width: '330px', height: '155px' }"
        ref="verify"
    ></Verify>
    <div class="login-header">
      <div class="wp">
        <div class="p13">
          正在前往登录...
          <!--          <LoginTop />-->
          <!--          <div class="login-bottom mt-4 d-flex">-->
          <!--            <LoginLeft />-->

          <!--            <div class="log-r">-->
          <!--              <p class="log-tips">用户登录</p>-->
          <!--              <div class="mt-4 login-main">-->
          <!--                <b-tabs-->
          <!--                  justified-->
          <!--                  nav-class="nav-tabs-custom"-->
          <!--                  content-class="pt-3 text-muted"-->
          <!--                >-->
          <!--                  <b-tab active>-->
          <!--                    <template v-slot:title>-->
          <!--                      <span-->
          <!--                        class="d-none d-sm-inline-block"-->
          <!--                        @click="changeLogin('account')"-->
          <!--                        >使用账号密码登录</span-->
          <!--                      >-->
          <!--                    </template>-->
          <!--                    <el-form-->
          <!--                      :model="formDataUsername"-->
          <!--                      :rules="rules"-->
          <!--                      ref="formDataUsername"-->
          <!--                    >-->
          <!--                      <el-form-item prop="username">-->
          <!--                        <div class="input-group">-->
          <!--                          <div class="input-group-prepend">-->
          <!--                            <span class="input-group-text icon-box"-->
          <!--                              ><img-->
          <!--                                src="@/assets/images/person/icon/user.png"-->
          <!--                                alt=""-->
          <!--                            /></span>-->
          <!--                          </div>-->
          <!--                          <el-input-->
          <!--                            type="text"-->
          <!--                            placeholder="请输入身份证件号"-->
          <!--                            v-model="formDataUsername.username"-->
          <!--                            aria-describedby="validationTooltipUsernamePrepend"-->
          <!--                            class="form-control h-100"-->
          <!--                          />-->
          <!--                        </div>-->
          <!--                      </el-form-item>-->
          <!--                      <el-form-item prop="password">-->
          <!--                        <div class="input-group">-->
          <!--                          <div class="input-group-prepend">-->
          <!--                            <span class="input-group-text icon-box"-->
          <!--                              ><img-->
          <!--                                src="@/assets/images/person/icon/lock.png"-->
          <!--                                alt=""-->
          <!--                            /></span>-->
          <!--                          </div>-->
          <!--                          <el-input-->
          <!--                            type="password"-->
          <!--                            placeholder="请输入登录密码"-->
          <!--                            v-model="formDataUsername.password"-->
          <!--                            autocomplete="close"-->
          <!--                            class="form-control h-100"-->
          <!--                          />-->
          <!--                        </div>-->
          <!--                      </el-form-item>-->
          <!--                      <div class="text-center">-->
          <!--                        <button-->
          <!--                          type="button"-->
          <!--                          class="btn btn-info mr-2 w-xl"-->
          <!--                          @click="checkLogin"-->
          <!--                        >-->
          <!--                          <template v-if="committing">-->
          <!--                            登录中-->
          <!--                            <i class="el-icon-loading"></i>-->
          <!--                          </template>-->
          <!--                          <template v-else> 登录系统 </template>-->
          <!--                        </button>-->
          <!--                        <a href="/register"-->
          <!--                          ><button type="button" class="btn btn-secondary w-xl">-->
          <!--                            注册账号-->
          <!--                          </button></a-->
          <!--                        >-->
          <!--                      </div>-->
          <!--                      <div class="login-span mt-3 text-center">-->
          <!--                        <a href="/forgot-password"><span>找回密码</span></a>-->
          <!--                        <span class="ml-2">|</span>-->
          <!--                        <a href="/findPassword"><span>账号赎回</span></a>-->
          <!--                        <span class="ml-2">|</span>-->
          <!--                        <a href="/browser"><span>浏览器要求</span></a>-->
          <!--                      </div>-->
          <!--                    </el-form>-->
          <!--                  </b-tab>-->

          <!--                  <b-tab>-->
          <!--                    <template v-slot:title>-->
          <!--                      <span-->
          <!--                        class="d-none d-sm-inline-block"-->
          <!--                        @click="changeLogin('tel')"-->
          <!--                        >使用短信验证码登录</span-->
          <!--                      >-->
          <!--                    </template>-->
          <!--                    <el-form-->
          <!--                      :model="formDataTel"-->
          <!--                      :rules="rulesTel"-->
          <!--                      ref="formDataTel"-->
          <!--                    >-->
          <!--                      <el-form-item prop="mobilePhone">-->
          <!--                        <div class="input-group">-->
          <!--                          <div class="input-group-prepend">-->
          <!--                            <span class="input-group-text icon-box"-->
          <!--                              ><img-->
          <!--                                src="@/assets/images/person/icon/phone.png"-->
          <!--                                alt=""-->
          <!--                            /></span>-->
          <!--                          </div>-->
          <!--                          <el-input-->
          <!--                            type="text"-->
          <!--                            placeholder="请输入手机号码（个人用户暂未开放）"-->
          <!--                            id="phone"-->
          <!--                            v-model="formDataTel.mobilePhone"-->
          <!--                            aria-describedby="validationTooltipUsernamePrepend"-->
          <!--                            class="form-control h-100"-->
          <!--                            readonly-->
          <!--                          />-->
          <!--                        </div>-->
          <!--                      </el-form-item>-->

          <!--                      <el-form-item prop="phoneCode">-->
          <!--                        <div class="input-group">-->
          <!--                          <div class="input-group-prepend">-->
          <!--                            <span class="input-group-text icon-box"-->
          <!--                              ><img-->
          <!--                                src="@/assets/images/person/icon/sms.png"-->
          <!--                                alt=""-->
          <!--                            /></span>-->
          <!--                          </div>-->
          <!--                          <el-input-->
          <!--                            type="text"-->
          <!--                            placeholder="请输入短信码"-->
          <!--                            v-model="formDataTel.phoneCode"-->
          <!--                            aria-describedby="validationTooltipUsernamePrepend"-->
          <!--                            class="form-control h-100"-->
          <!--                            readonly-->
          <!--                          />-->
          <!--                          <button-->
          <!--                            type="button"-->
          <!--                            class="btn btn-info h-100 verification-code"-->
          <!--                            @click="getCode"-->
          <!--                            disabled-->
          <!--                          >-->
          <!--                            获取短信码-->
          <!--                          </button>-->
          <!--                        </div>-->
          <!--                      </el-form-item>-->
          <!--                      &lt;!&ndash; <el-form-item prop="code">-->
          <!--                        <div class="input-group">-->
          <!--                          <button @click="useVerify" type="button" class="btn btn-light rounded-pill w-100">点击获取验证码</button>-->

          <!--                        </div>-->
          <!--                        <div class="input-group ">-->
          <!--                          <div class="input-group-prepend"><span-->
          <!--                              class="input-group-text icon-box"><img src="@/assets/images/person/icon/security.png"-->
          <!--                                alt=""></span></div><input v-model="formDataTel.code" type="text" placeholder="请输入验证码"-->
          <!--                            aria-describedby="validationTooltipUsernamePrepend" class="form-control h-100">-->
          <!--                          <img :src="imageCodeUrl" @click="imgCodeChange" class="check-img">-->
          <!--                        </div>-->
          <!--                      </el-form-item> &ndash;&gt;-->
          <!--                      <div class="login-btn">-->
          <!--                        <button-->
          <!--                          type="button"-->
          <!--                          class="btn btn-info w-100"-->
          <!--                          @click="checkLogin"-->
          <!--                          disabled-->
          <!--                        >-->
          <!--                          登录系统-->
          <!--                        </button>-->
          <!--                      </div>-->
          <!--                      <div class="login-span mt-3 text-center">-->
          <!--                        <span class="text-danger"-->
          <!--                          >说明：短信码登录仅对单位用户开放</span-->
          <!--                        >-->
          <!--                      </div>-->
          <!--                    </el-form>-->
          <!--                  </b-tab>-->
          <!--                </b-tabs>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="login-footer text-center mt-4">
            版权所有：四川省人力资源和社会保障厅人事人才测评服务专栏
            <!--            ©            {{ new Date().getFullYear() }} Rights Reserved-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
